














































import { Component, Vue } from "vue-property-decorator";
import preguntasModule from "@/store/modules/preguntas-module";
import { pregunta_for_mappeo } from "@/shared/dtos/pregunta_for_mappeo";
import { MessageService } from "@/shared/services/message-service";
import { singleOrDoubleRowClick } from "@/utils/dblclickrow";
import { RouterNames } from "@/router/routernames";
@Component({
  components: {},
})
export default class config_mapeador_lista extends Vue {
  public search: string = "";
  public created() {
    preguntasModule.getpreguntasMapeotoList();
  }

  public get datasource() {
    return preguntasModule.preguntas_mapeo_list;
  }

  public get headers() {
    return [
      {
        text: "ID",
        align: "start",
        value: "id",
      },
      {
        text: "Pregunta",
        align: "start",
        value: "nombre",
      },
      {
        text: "Nombre del mapeo",
        align: "start",
        value: "nombre_mapeo",
      },
      { text: "", value: "acciones", align: "end" },
    ];
  }

  /**
   * editar
   */
  public editar_rw(obj: pregunta_for_mappeo) {
    this.$router.push({
      name: RouterNames.mapeadorformulario,
      params: { id: obj.id },
    });
  }

  /**
   * add_new
   */
  public add_new() {
    this.$router.push({
      name: RouterNames.mapeadorformulario,
      params: { id: "-1" },
    });
  }
  /**
   *eliminar
   */
  public eliminar(obj: pregunta_for_mappeo) {
    MessageService.confirm(
      "¿Seguro que quiere eliminar " + obj.nombre + "?",
      "Eliminar",
      (x: boolean) => {
        if (x) {
          preguntasModule.eliminarpreguntas(obj).then(() => {
            preguntasModule.getpreguntasMapeotoList();
          });
        }
      }
    );
  }

  public dblclick(item: any, singleClickFunc: any, doubleClickFunc: any) {
    singleOrDoubleRowClick(item, singleClickFunc, doubleClickFunc);
  }
}
