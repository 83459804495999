import { store } from "@/store/store";
import { preguntas } from "@/shared/dtos/preguntas";
import {
  VuexModule,
  Mutation,
  getModule,
  Module,
  Action,
} from "vuex-module-decorators";
import { ssmHttpService } from "@/shared/services/http-service";
import { API } from "@/shared/api";
import { PreguntaRespuestaDto } from "@/shared/dtos/PreguntaRespuestaDto";
import { pregunta_for_list } from "@/shared/dtos/pregunta_for_list";
import { pregunta_for_mappeo } from "@/shared/dtos/pregunta_for_mappeo";

@Module({
  namespaced: true,
  name: "preguntasModule",
  store,
  dynamic: true,
})
class preguntasModule extends VuexModule {
  public preguntass: preguntas[] = [];
  public preguntas: preguntas = new preguntas();
  public pregunta_entera: PreguntaRespuestaDto = new PreguntaRespuestaDto();
  public preguntas_list: pregunta_for_list[] = [];
  public preguntas_mapeo_list: pregunta_for_mappeo[] = [];

  @Action({ commit: "onGetpreguntass" })
  public async getpreguntass() {
    return await ssmHttpService.get(API.preguntas);
  }

  @Action({ commit: "onGetpreguntasstoList" })
  public async getpreguntasstoList() {
    return await ssmHttpService.get(API.preguntas + "/get_all_lista/");
  }
  @Action({ commit: "onGetpreguntasMapeotoList" })
  public async getpreguntasMapeotoList() {
    return await ssmHttpService.get(API.preguntas + "/get_all_mapeo_lista/");
  }
  @Action({ commit: "onGetpreguntas" })
  public async getpreguntas(id: any) {
    return await ssmHttpService.get(API.preguntas + "/" + id);
  }

  @Action({ commit: "onGetpreguntasAl" })
  public async getpreguntasAl(id: any) {
    return await ssmHttpService.get(API.preguntas + "/get_all/" + id);
  }

  @Action
  public async guardarpreguntas(preguntas: preguntas) {
    /*toJson() no existe pero existe en la extends de BaseDto*/
    return await ssmHttpService.post(API.preguntas, preguntas.toJson());
  }
  @Action
  public async guardar_mapeo_de_pregunta(preguntas: preguntas) {
    /*toJson() no existe pero existe en la extends de BaseDto*/
    return await ssmHttpService.post(
      API.preguntas + "/create_map",
      preguntas.toJson(),
      true,
      false,
      true
    );
  }
  @Action
  public async guardarpreguntasAll(pregunta: PreguntaRespuestaDto) {
    /*toJson() no existe pero existe en la extends de BaseDto*/

    return await ssmHttpService.post(
      API.preguntas + "/create_all",
      JSON.stringify(pregunta)
    );
  }

  @Action
  public async modficarpreguntasAll(pregunta: PreguntaRespuestaDto) {
    /*toJson() no existe pero existe en la extends de BaseDto*/
    return await ssmHttpService.put(API.preguntas + "/update_all", pregunta);
  }

  @Action
  public async modificarpreguntas(preguntas: preguntas) {
    return await ssmHttpService.put(API.preguntas, preguntas);
  }

  @Action
  public async eliminarpreguntas(preguntas: preguntas) {
    return await ssmHttpService.delete(
      API.preguntas + "/" + preguntas.id,
      null,
      false
    );
  }

  @Mutation
  public onGetpreguntass(res: preguntas[]) {
    this.preguntass = res ? res.map((x) => new preguntas(x)) : [];
  }

  @Mutation
  public onGetpreguntasstoList(res: pregunta_for_list[]) {
    this.preguntas_list = res;
  }
  @Mutation
  public onGetpreguntasMapeotoList(res: pregunta_for_mappeo[]) {
    this.preguntas_mapeo_list = res
      ? res.map((x) => new pregunta_for_mappeo(x))
      : [];
  }

  @Mutation
  public onGetpreguntas(res: preguntas) {
    this.preguntas = new preguntas(res);
  }
  @Mutation
  public onGetpreguntasAl(res: PreguntaRespuestaDto) {
    this.pregunta_entera = res;
  }
}
export default getModule(preguntasModule);
